import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { DialogService } from 'app/service/dialog.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';

@Component({
  selector: 'dialog-confirm-reservation',
  templateUrl: './dialog-confirm-reservation.component.html',
  styleUrls: ['./dialog-confirm-reservation.component.scss']
})
export class DialogConfirmReservationComponent implements OnInit {
  reservationDetail: any;
  languageKey: any;
  languageCode: any;
  isEdit: boolean;
  isDelete: boolean;
  helper = Helper;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogConfirmReservationComponent>,
    private dialogService: DialogService,
    private ticketManagerService: TicketManagerService
  ) {
    if (data.languageKey) {
      this.languageKey = data.languageKey;
      this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
    }
    if (data.reservationDetail) {
      this.reservationDetail = data.reservationDetail;
    }
    if (data.isDelete) {
      this.isDelete = true;
      return;
    }
    if (this.reservationDetail.reservationId) {
      this.isEdit = true;
    }
  }

  ngOnInit(): void {}

  /**
   * confirm
   */
  confirm(): void {
    if (!this.isEdit) {
      if (this.isDelete) {
        //Delete
        const payload = {
          reservationId: this.reservationDetail.reservationId
        };
        this.ticketManagerService.deleteReservation(payload).subscribe(data => {
          this.close();
        });
      } else {
        //Add
        const payload = {
          stockId: this.reservationDetail.stockId,
          representativeName: this.reservationDetail.representativeName,
          reservationStatus: this.reservationDetail.reservationStatus,
          phoneNumber: this.reservationDetail.phoneNumber,
          email: this.reservationDetail.email,
          reservationNumberAdult: +this.reservationDetail.reservationNumberAdult,
          reservationNumberCustom1: +this.reservationDetail.reservationNumberCustom1,
          reservationNumberCustom2: +this.reservationDetail.reservationNumberCustom2,
          reservationNumberCustom3: +this.reservationDetail.reservationNumberCustom3,
          remarkAnswer: this.reservationDetail.remarkAnswer
        };
        this.ticketManagerService.postReservation(payload).subscribe(data => {
          if (!data || !data.reservationId) {
            return;
          }
          this.dialogRef.close(data.reservationId);
        });
      }
    } else {
      //Edit
      const payload = {
        reservationId: this.reservationDetail.reservationId,
        stockId: this.reservationDetail.stockId,
        representativeName: this.reservationDetail.representativeName,
        reservationStatus: this.reservationDetail.reservationStatus,
        phoneNumber: this.reservationDetail.phoneNumber,
        email: this.reservationDetail.email,
        remarkAnswer: this.reservationDetail.remarkAnswer
      };
      this.ticketManagerService.putReservation(payload).subscribe(data => {
        if (!data || !data.reservationId) {
          return;
        }
        this.dialogRef.close(data.reservationId);
      });
    }
  }

  /**
   * close
   */
  close(): void {
    this.dialogService.closeAllDialog();
  }

  /**
   * displayDateTime
   * @param time
   * @returns
   */
  displayDateTime(time: string): string {
    // Tách các thành phần thời gian từ chuỗi
    //2024-06-30T09:00:00+09:00
    if (!time) {
      return '';
    }
    const dateHours = time.split('T');
    const date = dateHours[0].split('-');
    const year = date[0];
    const month = date[1];
    const day = date[2];
    const newTime = dateHours[1];
    const hour = newTime.substring(0, 2);
    const minute = newTime.substring(3, 5);

    // Ghép lại các thành phần theo định dạng mong muốn
    const formattedDateTime = `${year}年${month}月${day}日  ${hour}:${minute}`;
    return formattedDateTime;
  }

  /**
   * changeDisplayStatus
   * @param status
   */
  changeDisplayStatus(status: string): string {
    if (!status) {
      return;
    }
    if (status == 'reserved') {
      return '収受済み';
    } else {
      return '未収受';
    }
  }
}
